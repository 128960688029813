@import "./variables";

body,
html {
    font-family: "Roboto";
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    @include custom-scroll-bar;
}
@media screen and (max-width: 768px) {
    header .MuiButton-root {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
    }
}

.navbar-menu {
    & > ul {
        li {
            .dropdown {
                position: absolute;
                left: 0;
                top: calc(100% + 9px);
                width: 170px;
                opacity: 0;
                visibility: hidden;
                transition: all 0.4s ease-in-out;
                &:hover {
                    opacity: 1;
                    visibility: visible;
                }
            }
            .MuiListItemButton-root {
                &:hover {
                    background-color: #deffc4;
                }
            }
            a {
                font-family: "Roboto-Medium";
                &:hover {
                    background-color: #deffc4;
                }
                span {
                    font-family: "Roboto-Medium";
                }
            }
            &:hover > .dropdown {
                opacity: 1;
                visibility: visible;
            }
        }
    }
}
.img-container {
    img {
        transition: all 0.4s ease-in-out;
    }
    &:hover {
        img {
            transform: scale(1.1);
        }
    }
}
.read-more {
    .description {
        font-size: 22px;
        line-height: 1.5;
        letter-spacing: 1.5px;
    }
    .bottom-sig {
        font-size: 26px;
        display: block;
        margin-top: 20px;
        font-family: "Roboto-Medium";
        text-align: right;
    }
}
footer .footer-nav {
    li {
        a {
            &:hover {
                background-color: #deffc4;
            }
            span {
                font-family: "Roboto-Medium";
            }
        }
    }
}
.counter {
    span {
        font-size: 70px;
    }
    small {
        font-size: 20px;
    }
}
.news-container {
    border-radius: 6px;
    padding: 20px;
    .header {
        padding: 0 0 10px;
    }
}
.journey-container {
    @extend .news-container;
}
.slick-prev:before,
.slick-next:before {
    color: $primary !important;
}
