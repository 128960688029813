$primary: #5abf08;

@mixin custom-scroll-bar() {
  &::-webkit-scrollbar-track {
    background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0) 35%,
      #fff 55%,
      #fff 57%,
      rgba(136, 255, 40, 0) 61%,
      rgba(136, 255, 40, 0) 100%
    );
  }
  &::-webkit-scrollbar {
    width: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: $primary;
    border-radius: 5px;
  }
}
$font-path: "../assets/fonts/";

// Roboto

@font-face {
  src: url("#{$font-path}Roboto-Regular.ttf");
  font-family: "Roboto";
}
@font-face {
  src: url("#{$font-path}Roboto-Medium.ttf");
  font-family: "Roboto-Medium";
}
@font-face {
  src: url("#{$font-path}Roboto-Light.ttf");
  font-family: "Roboto-Light";
}
@font-face {
  src: url("#{$font-path}Roboto-Bold.ttf");
  font-family: "Roboto-Bold";
}
@font-face {
  src: url("#{$font-path}Roboto-Black.ttf");
  font-family: "Roboto-Black";
}
